@use '@angular/material' as mat;
@use '../helpers/color-palettes' as palettes;

// mat-snackbar
html {
  /* border-radius */
  --mdc-snackbar-container-shape: 8px;

  .mdc-snackbar {
    &.mat-danger {
      --mdc-snackbar-container-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default)};
      --mdc-snackbar-supporting-text-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default-contrast)};
    }

    &.mat-success {
      --mdc-snackbar-container-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default)};
      --mdc-snackbar-supporting-text-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default-contrast)};
    }
  }
}
