.text-muted-light {
  color: #b7b7b7 !important;
}

.leading-reset {
  line-height: 1rem;
}

.ui.grid {
  margin: 0;
}

.title {
  font-size: 108%;
  font-weight: 400;
  letter-spacing: 0.25px;
  margin-bottom: 0;
  padding-bottom: 4px;
  padding-top: 8px;
}

.with-pointer {
  cursor: pointer;
}

.caption {
  padding-bottom: 10px;
}

.caption_title {
  box-sizing: border-box;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  line-height: 20px;
  min-height: 24px;
  padding-bottom: 1px;
  padding-top: 2px;
  border-bottom: 1px solid transparent;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.caption_content {
  box-sizing: border-box;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 20px;
  min-height: 24px;
  padding-bottom: 1px;
  padding-top: 2px;
  border-bottom: 1px solid transparent;
  font-weight: 400;
}

.global-error-snackbar {
  color: #fff;
}

.sb-table-row-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.sb-vertical-divider {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  height: 80%;
}

.sb-fw-500 {
  font-weight: 500;
}
