* {
  &:active,
  :focus {
    outline: none !important;
  }
}

body {
  min-width: 1024px;
  overflow: auto;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

a {
  text-decoration: none;
}

label {
  margin-bottom: 0;
}
