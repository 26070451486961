@use '@angular/material' as mat;

// mat-form-field
html {
  /*
    Fix notch outline issue
    https://stackoverflow.com/questions/75535246/angular-mat-form-field-not-working-properly-a-border-line-appears-inside-the-f
  */
  .mdc-notched-outline__notch {
    border-right: none;
  }
}
