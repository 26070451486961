@use '@angular/material' as mat;
@use '../helpers/color-palettes' as palettes;

// mat-button
.mat-mdc-button {
  &.mat-success {
    --mdc-text-button-label-text-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default)};
    --mat-text-button-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default)};
    --mat-text-button-ripple-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, 200)};
  }

  &.mat-danger {
    --mdc-text-button-label-text-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default)};
    --mat-text-button-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default)};
    --mat-text-button-ripple-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, 200)};
  }
}

// mat-flat-button
.mat-mdc-unelevated-button {
  &.mat-success {
    --mdc-filled-button-container-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default)};
    --mdc-filled-button-label-text-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default-contrast)};
    --mat-filled-button-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default-contrast)};
  }

  &.mat-danger {
    --mdc-filled-button-container-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default)};
    --mdc-filled-button-label-text-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default-contrast)};
    --mat-filled-button-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default-contrast)};
  }
}

// mat-raised-button
.mat-mdc-raised-button {
  &.mat-success {
    --mdc-protected-button-container-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default)};
    --mdc-protected-button-label-text-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default-contrast)};
    --mat-protected-button-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default-contrast)};
  }

  &.mat-danger {
    --mdc-protected-button-container-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default)};
    --mdc-protected-button-label-text-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default-contrast)};
    --mat-protected-button-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default-contrast)};
  }
}

// mat-stroked-button
.mat-mdc-outlined-button {
  &.mat-success {
    --mdc-outlined-button-label-text-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default)};
    --mat-outlined-button-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default)};
  }

  &.mat-danger {
    --mdc-outlined-button-label-text-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default)};
    --mat-outlined-button-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default)};
  }
}

// mat-icon-button
.mat-mdc-icon-button {
  &.mat-success {
    --mdc-icon-button-icon-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default)};
    --mat-icon-button-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default)};
  }

  &.mat-danger {
    --mdc-icon-button-icon-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default)};
    --mat-icon-button-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default)};
  }
}

// mat-fab and mat-mini-fab
.mat-mdc-fab,
.mat-mdc-mini-fab {
  &.mat-success {
    /* for  mat-fab and  mat-mini-fab */
    --mdc-fab-container-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default)};
    --mat-fab-foreground-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default-contrast)};
    --mat-fab-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$success-palette, default-contrast)};
  }

  &.mat-danger {
    --mdc-fab-container-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default)};
    --mat-fab-foreground-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default-contrast)};
    --mat-fab-state-layer-color: #{mat.m2-get-color-from-palette(palettes.$danger-palette, default-contrast)};
  }
}
